/* styles.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  height: y;
  background: linear-gradient(0deg, #f2f5ce 0%, #f2f5ce 100%);
}
